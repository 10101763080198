import { A, type AnchorProps } from "@solidjs/router";
import { HiSolidArrowTopRightOnSquare } from "solid-icons/hi";
import { Show, splitProps } from "solid-js";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./Anchor.module.css";

type Modifier = "surface";
interface CustomAnchorProps extends AnchorProps {
  target: AnchorTargets;
  "data-test-id": string;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
  modifier?: Modifier;
  label: string;
  small?: boolean;
  onClick?: (event: Event) => void;
}
type Props = RequireAtLeastOne<CustomAnchorProps, "accessiblePrefix" | "accessibleSuffix">;

export const Anchor = (props: Props) => {
  const [local, rest] = splitProps(props, [
    "label",
    "target",
    "accessiblePrefix",
    "accessibleSuffix",
    "modifier",
    "small",
    "onClick",
  ]);
  return (
    <A
      {...rest}
      class={classNames(
        styles.anchor,
        local.modifier === "surface" ? styles.anchorSurface : "",
        local.target === "_blank" ? styles.anchorNewWindow : "",
        local.small ? styles.anchorSmall : "",
      )}
      href={rest.href}
      target={local.target}
      tabIndex={0}
      onClick={local.onClick}
    >
      <Show when={local.accessiblePrefix}>
        <span class="screen-reader">{local.accessiblePrefix}</span>
      </Show>
      <span class={styles.anchorLabel}>{local.label}</span>
      <Show when={local.accessibleSuffix}>
        <span class="screen-reader">{local.accessibleSuffix}</span>
      </Show>
      <Show when={local.target === "_blank"}>
        <StIcon icon={HiSolidArrowTopRightOnSquare} class={styles.anchorIcon} size={14} />
        <span class="screen-reader">opens in a new window.</span>
      </Show>
      <span class={styles.anchorUnderline} aria-hidden="true" />
    </A>
  );
};
